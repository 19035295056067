import(/* webpackMode: "eager" */ "/app/apps/tierra-bonita/theme/ThemeProviders.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tierra-bonita/tierraLayout/TierraLayout.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@apollo+experimental-nextjs-app-support@0.6.0_@apollo+client@3.8.8_next@14.1.0_react@18.2.0/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@apollo+experimental-nextjs-app-support@0.6.0_@apollo+client@3.8.8_next@14.1.0_react@18.2.0/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-intl@3.5.3_next@14.1.0_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-Regular.otf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-Medium.otf\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-SemiBold.otf\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-Bold.otf\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-ExtraBold.otf\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-Black.otf\",\"weight\":\"900\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-ExtraLight.otf\",\"weight\":\"200\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-Light.otf\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/rag/RAG-Sans-1.2-Thin.otf\",\"weight\":\"100\"}],\"display\":\"swap\",\"variable\":\"--rag\"}],\"variableName\":\"rag\"}");
import(/* webpackMode: "eager" */ "/app/packages/apollo/apollo-provider.js");
import(/* webpackMode: "eager" */ "/app/packages/utils/gtm.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/ReactQueryContext.tsx");
import(/* webpackMode: "eager" */ "/app/packages/utils/searchResults.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/useApi.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/UseOutsideClick.tsx")