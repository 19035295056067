export function getNonUmbracoUrl(url?: string) {
  if (!url) {
    return "/"
  }
  if (url?.includes("pages/home")) {
    return !url?.includes("en-us") ? "/he" : "/"
  }
  if (url?.includes("/shared")) {
    return !url?.includes("en-us")
      ? "/he" + url?.replace(/.*\/general-information/, "")
      : url?.replace(/.*\/general-information/, "") || "/"
  }
  if (!url?.includes("pages")) {
    return url
  }
  return !url?.includes("en-us")
    ? "/he" + url?.replace(/.*\/pages/, "")
    : url?.replace(/.*\/pages/, "") || "/"
}
