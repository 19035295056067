"use client"
import styled from "@emotion/styled"
import { Button } from "@mui/material"

import { usePathname } from "next/navigation"
import { FC, Suspense, useRef } from "react"
import { Desktop } from "ui"
import Analytics from "ui/layout/Analytics"
import TierraFooter from "./TierraFooter"
import TierraHeader from "./TierraHeader"

const StyledMain = styled.div<{ isParking: boolean; isContent: boolean }>`
  height: 100%;
  background-image: url("/tierra-background.svg");
  background-size: cover;
  background-position: center;
  padding-top: 64px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => (props.isContent ? "0px" : "98px")};
    height: ${(props) => (props.isParking ? "100vh" : "100%")};
  }

  :focus {
    outline: 0;
  }
`

const StyledButton = styled(Button)`
  position: fixed;
  z-index: -10;
  background-color: black;
  color: white;
  top: -100px;
  &:focus-visible {
    z-index: 9999999;
    top: 0px;
  }
`

const TierraLayout: FC<any> = ({
  locale,
  children,
  menu_data,
  footer_data,
  companyName,
  subcompany,
  projectType,
}) => {
  const mainRef = useRef<HTMLDivElement>(null)

  const pathname = usePathname()

  const logoArray = footer_data?.header?.properties?.[0]?.value?.mediaItems
  const logo = Array.isArray(logoArray) ? logoArray[0] : logoArray

  const handleClick = () => {
    if (mainRef.current) {
      mainRef.current.focus()
    }
  }
  return (
    <>
      <Suspense>
        <Analytics
          companyName={companyName}
          locale={locale}
          subcompany={subcompany}
          projectType={projectType}
        />
      </Suspense>

      <Desktop>
        <StyledButton id="skip-to-content" tabIndex={3} onClick={handleClick}>
          {locale === "he" ? "דלג לתוכן המרכזי" : "skip navigation"}
        </StyledButton>
      </Desktop>

      <TierraHeader logo={logo} data={menu_data} locale={locale} />

      <>
        <StyledMain
          isContent={pathname.includes("content")}
          isParking={pathname === "/parking" || pathname === "/en/parking"}
          id="mainContent"
          tabIndex={-1}
          ref={mainRef}
          role="main"
        >
          {children}
        </StyledMain>
        <TierraFooter data={footer_data} companyName={companyName} />
      </>
    </>
  )
}

export default TierraLayout
